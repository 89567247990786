import { PureComponent, Fragment } from 'react'
import { Translate } from '../services/i18n'

class Base extends PureComponent {
  static defaultProps = {
    mod: 'info',
    className: ''
  }

  renderContent() {
    return this.props.children;
  }

  render() {
    return (
      <div className={`alert alert-${this.props.mod} ${this.props.className}`}>
        {this.renderContent()}
      </div>
    )
  }
}

export class WarningAlert extends Base {
  static defaultProps = {
    ...Base.defaultProps,
    mod: 'warning'
  }

  renderContent() {
    return (
      <Fragment>
        <span className="fa fa-exclamation-triangle fa-3x mr-1" />
        {this.props.message ? <Translate value={this.props.message} /> : this.props.children}
      </Fragment>
    );
  }
}

export default Base;