import ReactDOM from 'react-dom'
import stubTrue from 'lodash-es/stubTrue'
import isObject from 'lodash-es/isObject'
import snakeCase from 'lodash-es/snakeCase'
import transform from 'lodash-es/transform'

let strCompare;
if (window.Intl && window.Intl.Collator) {
  var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  strCompare = collator.compare;
} else {
  strCompare = (a, b) => a.localeCompare(b);
}

export const reactBridge = (Component, getProps, node) => {
  ReactDOM.render(<Component {...getProps()} />, node);

  return () => {
    ReactDOM.unmountComponentAtNode(node);
    ReactDOM.render(<Component {...getProps()} />, node);
  }
}

export const toggle = (list, value) => {
  var result = [].concat(list);
  let i = result.indexOf(value);
  if(i === -1) {
    result.push(value);
  } else {
    result.splice(i, 1);
  }
  return result;
}

export const LOCALES = gon.app.locale;

const ATTR_LOCALES = [LOCALES.current, LOCALES.default, 'en'].concat(LOCALES.list);
export const tAttr = value => {
  if (typeof value !== 'object' || Array.isArray(value) || value === null) return '';

  for(let i = 0; i < ATTR_LOCALES.length; i++) {
    let locale = ATTR_LOCALES[i];
    if (value.hasOwnProperty(locale) && value[locale]) return value[locale];
  }

  return '';
}

export const sortByLabel = (a, b) => strCompare(a.label, b.label);
export const sortByName = (a, b) => strCompare(a.name, b.name);
export const sortByFullName = (a, b) => strCompare(a.$name, b.$name);
export const sortByTAttr = (a, b, attr) => strCompare(tAttr(a[attr]), tAttr(b[attr]));
export const sortBySortKey = (a, b) => b.sortKey - a.sortKey;
export const sortBySortKeyName = (a, b) => a.sortKey === b.sortKey ? sortByName(a, b) : sortBySortKey(a, b);
export const sortBySortKeyTAttr = (a, b, attr) => a.sortKey === b.sortKey ? sortByTAttr(a, b, attr) : sortBySortKey(a, b);
export const sortBySortKeyTName = (a, b) => sortBySortKeyTAttr(a, b, 'name');
export const sortByCreation = (a, b) => a.createdAt - b.createdAt;
export const sortByCreationDesc = (a, b) => b.createdAt - a.createdAt;

export const isStrBlank = value => value.trim().length === 0;

export const mapByIds = (list, ids, attrName = 'id') => {
  var result = [];
  if (ids.length === 0) return result;
  for (var i = 0, item; i < list.length; i++) {
    item = list[i];
    if (ids.includes(item[attrName])) {
      result.push(item);
      if (result.length == ids.length) {
        return result;
      }
    }
  }
  return result;
}

export const mapValue = o => o.value;

export const findById = (list, id, def, attrName = 'id') => {
  if (def === undefined) def = { [attrName]: id };
  if (id === null || id === undefined) return def;

  return list.find(c => c[attrName] == id) || def;
}

export const buildId = () => `${Date.now()}-${Math.random()}`;

export const toName = (firstName, lastName) => `${firstName} ${lastName}`

export const scrollToTop = value => {
  let top;
  if (value === 'main') {
    top = 130;
  } else if (Number.isInteger(value)) {
    top = value;
  } else {
    top = 0;
  }

  try {
    window.scroll({ top, behavior: 'smooth' });
  } catch (err) {
    console.error(err);
  }
}

export const nool = () => null;

export const toEnumValue = (enumId, label) => {
  let result = Object.entries(window.EVA.enums[enumId]).find(([_, c]) => c === label)[0];
  return parseInt(result);
}

export const toEnumLabel = (enumId, value) => window.EVA.enums[enumId][value];

// skip filter if set explicity (false) or field is not present (undefined)
export const filterByIdsF = ids => [false, undefined].includes(ids) ? stubTrue : (item => ids.includes(item.id));
export const filterDeleted = c => !c.isDeleted;

export const camelize = str => str.replace(/[\W_]+(.)/g, (_match, chr) => chr.toUpperCase());

export const stopPropagation = e => e.stopPropagation();

function keyValToParams (acc, value, key) {
  acc[snakeCase(key)] = isObject(value) ? _objToParams(value) : value;
}

function _objToParams(obj) {
  return transform(obj, keyValToParams);
}

export const objToParams = _objToParams;
