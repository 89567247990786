import ReactDOM from 'react-dom'
import { Fragment, PureComponent } from 'react'

export const ModalHeader = ({ onClose, children }) => (
  <Fragment>
    <span className="modal-close link" onClick={onClose} />
    {(children && children !== true) ? <div className="modal-header">{children}</div> : null}
  </Fragment>
);

class Modal extends PureComponent {
  static defaultProps = {
    skin: 'default',
    size: 'md',
    header: false
  }

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    document.getElementById('modals').appendChild(this.el);
  }

  componentWillUnmount() {
    document.getElementById('modals').removeChild(this.el);
  }

  renderContent() {
    return this.props.children;
  }

  render() {
    return ReactDOM.createPortal(
      <Fragment>
        <div className={`modal eva-modal modal--${this.props.skin} fade in show`}>
          <div className={`modal-dialog modal-${this.props.size}`}>
            <div className="modal-content">
              {
                this.props.header && (
                  <ModalHeader onClose={this.props.onClose}>
                    {this.props.header}
                  </ModalHeader>
                )
              }
              {this.renderContent()}
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade in" />
      </Fragment>,
      this.el
    );
  }
}

export const openModal = (render, custom = {}) => new Promise((resolve, reject) => {
  let node = document.createElement('div');
  let opts = {
    ...custom,
    onSuccess(...args) {
      ReactDOM.unmountComponentAtNode(node);
      resolve(...args);
    },
    onCancel(...args) {
      ReactDOM.unmountComponentAtNode(node);
      reject(...args);
    }
  };

  ReactDOM.render(<Modal {...opts}>{render(opts)}</Modal>, node);
});

export default Modal;
