import { Component } from 'react'
import Alert from 'share/components/alert'
import AlarmIcon from 'share/components/alarm-icon'
import { TooltipWrap } from 'share/components/tooltip'
import { I18n, Translate } from 'share/services/i18n'
import { tAttr } from 'share/services/utils'
import { fetchOne, fetchPage } from 'share/services/api'

const INFO_CONFIG = { page: 1, per_page: 1, filter: { status_in: [1, 2] } };
const fetchInfo = () => Promise.all([
  fetchOne('/me/location', 'locations', 'location'),
  fetchPage('/alarms', 'alarms', INFO_CONFIG)
]).then(([location, { list, total, meta }]) => {
  try {
    if (meta.timestamps && navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ id: 'eva:timestamps', timestamps: meta.timestamps });
    }
  } catch(_e) {}

  let alarm = list[0];
  let sectors = alarm ? [].concat(alarm.$buildingSectors, alarm.$alarmSectors, alarm.$roomSectors) : [];
  return { alarm, sectors, total, hasBlockingAlarms: location.hasBlockingAlarms };
});

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = { info: null };
    this.fetchInfo = this.fetchInfo.bind(this);
  }

  componentDidMount() {
    this.fetchInfo();
  }

  fetchInfo() {
    fetchInfo().then(info => {
      this.setState({ info }, () => setTimeout(this.fetchInfo, 15000));
    })
  }

  render() {
    let { info } = this.state;
    if (info === null) return null;

    if (info.hasBlockingAlarms) {
      let alarmDetails, actionBtn;
      if (info.alarm) {
        // in alarm dashboard a user will see it
        if (this.props.inDashboard) return null;

        alarmDetails = (
          <ul className="alert_details mt-3">
            <li>
              <TooltipWrap
                value={tAttr(info.alarm.$alarmType.name)}
                render={ref =>
                  <span ref={ref}>
                    <AlarmIcon mod="md" icon={info.alarm.$alarmType.icon} />
                  </span>
                }
              />
            </li>
            <li>{info.sectors.slice(0, 30).map(s => s.name).join(' | ')}</li>
            <li>
              <span className="badge">{I18n.rCount(info.total, 'alarm')}</span>
            </li>
          </ul>
        );

        actionBtn = (
          <a href={Routes.dashboardAlarms()} className="btn btn-primary ml-2">
            <Translate value="ui.alarm_status_panel.dashboard_link" />
          </a>
        );
      }

      return (
        <Alert mod="-alarm">
          <span className="fa fa-warning alert_icon" />
          {actionBtn}
          <p><Translate value="ui.alarm_status_panel.alarm_present" /></p>
          {alarmDetails}
        </Alert>
      );
    }

    return null;
  }
}
