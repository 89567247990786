import PropTypes from 'prop-types'
import { I18n } from '../services/i18n'

export const attachTooltip = (node, tooltip, placement) => {
  let $node = jQuery(node).tooltip({
    title: I18n.t(tooltip),
    placement,
    container: false,
    trigger: 'manual',
    html: true
  });

  var timeoutId;

  node.addEventListener('mouseenter', () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => $node.tooltip('show'), 300);
  });
  node.addEventListener('mouseleave', () => {
    clearTimeout(timeoutId);
    setTimeout(() => $node.tooltip('hide'), 1200);
  });
}

class Tooltip extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    placement: PropTypes.string.isRequired
  }

  static defaultProps = {
    placement: 'top',
    icon: 'info-circle'
  }

  constructor() {
    super();
    this.ref = React.createRef();
  }

  componentDidMount() {
    attachTooltip(this.ref.current, this.props.value, this.props.placement);
  }

  componentWillUnmount() {
    jQuery(this.ref.current).tooltip('hide');
  }

  render() {
    let { value, placement, icon, className, ...rest } = this.props;
    return (<span ref={this.ref} className={`fa fa-${icon} fa--tooltip ${className}`} {...rest} />);
  }
}

export class TooltipWrap extends Tooltip {
  static propTypes = {
    value: PropTypes.string.isRequired,
    placement: PropTypes.string.isRequired
  }

  render() {
    return this.props.render(this.ref);
  }
}

export default Tooltip;
