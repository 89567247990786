import saveAs from 'file-saver'
import mime from 'mime-types'
import toastr from './toastr'
import { I18n } from './i18n'

const toUrl = part => `${gon.app.client.url}${part}`;

export const toQuery = config => {
  if(!config) return '';

  var result = [];
  if(config.hasOwnProperty('page') && config.page !== null) {
    result.push(`page=${config.page}`);
    if(config.per_page) {
      result.push(`per_page=${config.per_page}`);
    }
  }

  if(config.hasOwnProperty('filter')) {
    Object.keys(config.filter).forEach(attrName => {
      let val = config.filter[attrName];
      if (val === null) return;
      let value = Array.isArray(val) ? val : [val];
      value.forEach(item => result.push(`${attrName}=${encodeURI(item)}`));
    });
  }

  if(config.hasOwnProperty('q') && config.q !== null) {
    result.push(`q=${encodeURI(config.q.toLocaleLowerCase())}`);
  }

  return (result.length === 0) ? '' : `?${result.join('&')}`;
}

const headers = (custom = {}) => {
  let base = {
    'x-evalarm-api-session': window.gon.app.client.token,
    'x-evalarm-api-version': window.EVA.apiVersion
  };
  return new Headers(Object.assign(base, custom));
}

export const responseHandler = resp => {
  let { headers, status } = resp;

  if (status === 401 && window.gon.app.client.autologin) {
    window.location.reload();
  }

  if(status === 404 || 500 <= status) {
    if (500 <= status) {
      toastr(I18n.t('core.api_error'), { theme: 'danger' });
    }
    return Promise.reject({ headers, status });
  } else if ((resp.headers.get('Content-Type') + '').indexOf('json') !== -1 && resp.status !== 204) {
    return resp.json().then(
      json => ({ headers, status, json }),
      () => ({ headers, status, json: null })
    );
  } else {
    return { headers, status, json: null };
  }
}

const doRequest = (url, method, options) => {
  var config = { method, headers: headers(options.headers) };
  if(options.body) {
    if(options.body instanceof FormData) {
      config.body = options.body;
    } else {
      config.headers.append('Content-Type', 'application/json');
      config.body = JSON.stringify(options.body);
    }
  }

  if (options.cached) {
    config.headers.append('x-cached-resources', Math.ceil(Date.now() / 1000));
    config.headers.append('x-cached-location', gon.app.id);
  }

  return fetch(toUrl(url), config).then(responseHandler, error => {
    toastr("There's API issue. Please contact support@evalarm.de", { theme: 'danger' });
    return Promise.reject(error);
  });
}

if (process.env.RAILS_ENV === 'development') {
  window.apiFetch = (url, method = 'GET', options = {}) => doRequest(url, method, options);
}

export const get = (url, options = {}) => doRequest(url, 'GET', options);

export const post = (url, body, options = {}) => doRequest(url, 'POST', { ...options, body });

export const put = (url, body, options = {}) => doRequest(url, 'PUT', { ...options, body });

export const patch = (url, body, options = {}) => doRequest(url, 'PATCH', { ...options, body });

export const del = (url, options = {}) => doRequest(url, 'DELETE', options);

export const fetchList = (path, route, config = {}, key = route) => get(`${path}${toQuery(config)}`, config).
  then(resp => {
    let { [key]: listData, ...meta } = resp.json;
    let list = app.schema.normalizeList(listData, route);
    list.$meta = meta;
    list.$meta.$path = path;
    list.forEach(item => {
      item.$meta = item.$meta || {};
      item.$meta.$path = `${path}/${item.id}`;
    });
    return list
  }, Promise.reject);

var fetchAllListCache = {};
export const fetchAllList = (route, config = {}) => {
  let path = config.path || `/${route}`;

  if (fetchAllListCache.hasOwnProperty(path)) {
    return new Promise(resolve => resolve(fetchAllListCache[path]));
  }

  return fetchList(path, route, { ...config, cached: true }).then(list => {
    fetchAllListCache[path] = list;
    return list;
  }, Promise.reject);
}

fetchAllList.resetCache = path => delete fetchAllListCache[path]

export const fetchPage = (path, route, config, key = route) => {
  let params = { per_page: 25, ...config };
  return get(`${path}${toQuery(params)}`).then(resp => {
    let { total, ...meta } = resp.json;
    delete meta[key];
    let list = app.schema.normalizeList(resp.json[key], route);
    list.$meta = { per_page: params.per_page, ...meta, total, $path: path };
    list.forEach(item => {
      item.$meta = item.$meta || {};
      item.$meta.$path = `${path}/${item.id}`;
    });
    return { list, total, meta };
  }, Promise.reject);
}

export const fetchOne = (path, route, key) => get(path).then(resp => {
  let resource = app.schema.normalize(resp.json[key], route);
  resource.$meta = resource.$meta || {};
  resource.$meta.$path = path;
  return resource;
}, Promise.reject);

// sugar methods
export const fetchListByIds = (path, route, ids) => {
  if (ids.length === 0) {
    return new Promise(resolve => resolve([]));
  }
  // send only unique list of ids as api duplicates records
  // if there is a few identical ids
  let uniqIds = Array.from(new Set(ids));
  // to fix to long query (2048 chars max)
  if (50 < uniqIds.length) {
    return fetchAllList(route, { path });
  } else {
    return fetchList(path, route, { filter: { id_in: uniqIds } });
  }
}

export const fetchRelationIds = relationIdsPath => get(relationIdsPath).then(resp => resp.json, Promise.reject);

export const fetchListByRelationIds = (relationIdsPath, path, route) =>
  fetchRelationIds(relationIdsPath).then(ids => fetchListByIds(path, route, ids), Promise.reject)

export const fetchCount = path => get(`${path}${toQuery({ page: 1, per_page: 1 })}`)
                                    .then(resp => resp.json.total, Promise.reject);

var fileCache = {};

const downloadFileBlob = path => {
  let url = path.includes('://') ? path : toUrl(`/files${path}`);
  return fetch(url, { headers: headers() }).then(resp => {
    if (resp.status === 200) {
      return resp.blob()
    } else {
      return Promise.reject(resp);
    }
  }, Promise.reject);
}

export const getFileUrl = (url, forceCache = false) => new Promise((resolve, reject) => {
  if (fileCache.hasOwnProperty(url) && !forceCache) {
    return resolve(fileCache[url]);
  }

  downloadFileBlob(url).then(blob => {
    let fileUrl = URL.createObjectURL(blob);
    fileCache[url] = fileUrl;
    resolve(fileUrl);
  }, reject);
});

const blobToExt = blob => mime.extension(blob.type) || '';

const toFullFileName = (filename, extName) =>
  filename.endsWith(extName) ? filename : `${filename}.${extName}`;

export const saveBlob = (blob, opts) => saveAs(blob, toFullFileName(opts.filename, blobToExt(blob)))

export const downloadFile = (url, opts = { filename: 'EVAlarm' }) =>
  downloadFileBlob(url).then(blob => saveBlob(blob, opts));

export const preloadFile = (url, filename = 'EVAlarm')  => downloadFileBlob(url).then(blob => ({
  url: URL.createObjectURL(blob),
  download(custom = {}) {
    return new Promise(resolve => {
      saveBlob(blob, { filename, ...custom });
      resolve();
    });
  }
}));
