import { scrollToTop } from '../services/utils'
import toastr from '../services/toastr'

let onGlobalClick = e => {
  let link;
  var target = e.target;
  while (target !== e.currentTarget) {
    if(target === null) return;
    if(target.tagName === 'A') {
      link = target;
      break;
    }
    target = target.parentElement;
  }
  if (!link) return;

  let href = link.getAttribute('href');

  if (href === '#') {
    e.preventDefault();
  }

  if (link.dataset.hasOwnProperty('scrollTop')) {
    scrollToTop();
  }

  if(link.dataset.hasOwnProperty('popup')) {
    toastr(link.dataset.popup, { theme: 'warning' });
  }
}

let tryFocusInput = () => {
  try {
    let node = document.querySelector('form :read-write');
    if (node) node.focus();
  } catch (e) {}
}

export default () => {
  window.scrollToTop = scrollToTop;

  document.addEventListener('DOMContentLoaded', () => {
    document.body.addEventListener('click', onGlobalClick);
    document.addEventListener('shown.bs.modal', tryFocusInput)
    tryFocusInput();
  });
}
