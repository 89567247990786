const DEFAULTS = { title: null, theme: 'info', size: 'md' };

export default (message, custom = {}) => {
  let holder = document.getElementById('toastr');
  if (holder === null) return;

  let itemNode = document.createElement('div');
  let options = { ...DEFAULTS, ...custom };
  itemNode.classList.add('toastr_item', `toastr_item--${options.theme}`, `toastr_item--${options.size}`);

  let messageNode = document.createElement('div');
  messageNode.classList.add('toastr_message');

  if (options.title !== null) {
    let titleNode = document.createElement('h3');
    titleNode.innerText = options.title;
    messageNode.appendChild(titleNode);
  }

  let textNode = document.createElement('p');
  textNode.innerText = message;
  messageNode.appendChild(textNode);
  itemNode.appendChild(messageNode);

  let timeoutId;
  let onItemHide = () => {
    clearTimeout(timeoutId);
    holder.removeChild(itemNode);
  }

  itemNode.addEventListener('click', onItemHide);
  timeoutId = setTimeout(onItemHide, 4000);

  holder.appendChild(itemNode);
}
