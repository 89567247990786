import ReactPaginate from 'react-js-pagination'

export default ({ page, per_page, total, onChange }) => {
  if (total === 0 || (page === 1 && total <= per_page)) return null;

  return (
    <div className="text-center">
      <ReactPaginate
        activePage={page}
        itemsCountPerPage={per_page}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        itemClass="page-item"
        linkClass="page-link"
        firstPageText={<i className='fa fa-angle-double-left' />}
        lastPageText={<i className='fa fa-angle-double-right' />}
        prevPageText={<i className='fa fa-angle-left' />}
        nextPageText={<i className='fa fa-angle-right' />}
        onChange={onChange}
        hideDisabled
      />
    </div>
  );
}
