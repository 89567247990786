import { responseHandler } from './api'

const base = (path, method = 'GET', config = {}) => {
  var payload = { method, credentials: 'same-origin', headers: new Headers() };
  let meta = document.head.querySelector('meta[name=csrf-token]')
  if(meta) {
    payload.headers.append('X-CSRF-Token', meta.getAttribute('content'));
  }
  payload.headers.append('accept', 'application/json');
  if(config.body) {
    if(config.body instanceof FormData) {
      payload.body = config.body;
    } else {
      payload.body = JSON.stringify(config.body);
      payload.headers.append('content-type', 'application/json');
    }
  }
  return window.fetch(path, payload);
}

export const commit = (path, method, config) => {
  return base(path, method, config).then(responseHandler).then(resp => {
    if(200 <= resp.status && resp.status < 300) {
      return resp;
    } else {
      return Promise.reject(resp);
    }
  });
}

export default base;
