import 'share/core'
import AlarmStatusPanel from 'components/alarm-status-panel'
import BgStatus from 'components/bg-status'
import { openModal } from 'share/components/modal'
import { fetchOne } from 'share/services/api'
import { forResource } from 'share/services/forms'

window.addEventListener('load', () => {
  renderReactSections({
    alarmStatus: AlarmStatusPanel,
    bgStatus: [BgStatus, { initStatus: (gon.app || {}).bg_jobs || false }]
  });

  let node = document.getElementById('editProfile');
  if (node) {
    import('features/users/form').then(({ ProfileForm }) => {
      node.addEventListener('click', () =>
        fetchOne('/me/user', 'users', 'user').then(user =>
          openModal(props => <ProfileForm env={{ modelName: 'user', isNew: false }} form={forResource(user)} {...props} />)));
    });
  }
})
