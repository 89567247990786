import pluralize from 'pluralize'

const copy = value => {
  if(value === null) return null;
  if (typeof value === 'object') return JSON.parse(JSON.stringify(value));
  return value;
}

export const forResource = (resource, associations = []) => {
  var form = {};
  var route = pluralize(resource.route, 2);
  Object.keys(window.EVA.schema[route]).forEach(key => form[key] = copy(resource[key]));

  // pick associations
  associations.forEach(name => {
    let item = resource["$" + name];
    // if association is array - create a copy to prevent a mutation
    form[name] = Array.isArray(item) ? [].concat(item) : item;
  });

  return form;
}

export const forRoute = route => Object.assign({}, copy(window.EVA.schema.defaults[pluralize(route, 2)]));
