import { Component, Fragment, PureComponent } from 'react'
import { Translate } from '../services/i18n'
import Message from './message'
import Pagination from './pagination'
import MockTable from './mock-table'

class Table extends PureComponent {
  static defaultProps = {
    className: ''
  }

  render() {
    let { ajax, config, list } = this.props;
    let content, pagination;
    if (list === null) {
      return <MockTable />;
    } else if (list.length === 0) {
      content = (
        <tbody>
          <tr>
            <td colSpan="200">
              <Message><Translate value="core.empty_list" /></Message>
            </td>
          </tr>
        </tbody>
      );
    } else {
      content = (
        <tbody>{list.map(this.props.renderRow)}</tbody>
      );
    }

    if (config) {
      pagination = (
        <Pagination
          page={config.page}
          per_page={config.per_page}
          total={config.total}
          onChange={this.props.onPageChange}
        />
      );
    }

    return (
      <Fragment>
        <table className={`listTable ${this.props.className} ${ajax ? ' listTable--mock' : ''}`}>
          <thead>{this.props.renderHeader()}</thead>
          {content}
        </table>
        {pagination}
      </Fragment>
    );
  }
}

export class LoadingTable extends Table {
  static defaultProps = {
    ajax: true,
    renderHeader: () => (
      <tr><th></th></tr>
    ),
    list: null
  }
}

export class PaginationTable extends Component {
  static defaultProps = {
    perPage: 10
  };

  static getDerivedStateFromProps(props, state) {
    let total = (props.list === null) ? null : props.list.length;
    if (state.total === total) return null;

    let result = { total };
    if (total === null) {
      result.page = 1;
    } else if (total <= (state.page - 1) * props.perPage) {
      result.page = Math.max(state.page - 1, 1);
    }
    return result;
  }

  constructor(props) {
    super(props);
    this.state = { page: 1, total: null }
    this.onPageChange = this.onPageChange.bind(this);
  }

  onPageChange(page) {
    this.setState({ page });
  }

  render() {
    let { perPage, list, ...props } = this.props;
    let { page, total } = this.state;
    let config = { page, total, per_page: perPage };

    let pageList;
    if (list === null) {
      pageList = list;
    } else {
      pageList = list.slice((page - 1) * perPage, page * perPage);
    }
    return <Table config={config} list={pageList} onPageChange={this.onPageChange} {...props} />
  }
}

export default Table;
