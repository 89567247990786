import { I18n as BaseI18n } from 'react-i18nify'
export { Translate } from 'react-i18nify'

BaseI18n.setHandleMissingTranslation((key, replacements) => {
  if (replacements && replacements.fallback) {
    return replacements.fallback(key, replacements);
  } else if (replacements && replacements.default) {
    return replacements.default;
  }
  return key;
});

export const I18n = {
  setup(config, translations) {
    this.config = config;
    this.locale = config.current;
    BaseI18n.setTranslations({ [config.current]: translations });
    BaseI18n.setLocale(config.current);
  },
  t: BaseI18n.t.bind(BaseI18n),
  r(model) {
    return BaseI18n.t(`models.${model}.one`);
  },
  rs(model) {
    return BaseI18n.t(`models.${model}.other`);
  },
  rCount(count, model) {
    let parts = this.a('ng_count', model).split(',');
    return `${count} ${parts[count === 1 ? 0 : 1]}`;
  },
  // action on a model
  a(name, model, custom = {}) {
    let args = {
      r: this.r(model).toLowerCase(),
      R: this.r(model),
      rs: this.rs(model).toLowerCase(),
      RS: this.rs(model),
      ...custom
    }
    return BaseI18n.t(`actions.${model}.${name}`, {
      ...args,
      fallback: () => BaseI18n.t(`actions.base.${name}`, args)
    });
  }
}
