import { Component, Fragment } from 'react'
import { LoadingTable } from './table'
import history from '../services/history'

const getTabId = tabs => {
  let result = location.search.match(/subnav=(\w+)/);
  if (result !== null) {
    let suggestion = result[1];
    if (tabs.includes(suggestion)) {
      return suggestion;
    }
  }
  return tabs[0];
}

export const syncLocationPath = tab =>
  history.replace(`${location.pathname}?subnav=${tab}`);

const buildCache = tabs => tabs.reduce((acc, key) => {
  acc[key] = null;
  return acc;
}, {});

export default class extends Component {
  static defaultProps = {
    inline: false
  }

  constructor(props, custom = {}) {
    super();
    let tabs = Object.keys(props.tabs || this.constructor.tabs);
    this.cache = buildCache(tabs);
    this.state = { tab: getTabId(tabs), ...custom };
    this.onTabChange = this.onTabChange.bind(this);
    this.onTabStateChange = this.onTabStateChange.bind(this);
    this.renderTab = this.renderTab.bind(this);
  }

  onTabChange(tab) {
    this.setState({ tab });
    if (!this.props.inline) {
      syncLocationPath(tab);
    }
  }

  onTabStateChange(data) {
    this.cache[this.state.tab] = data;
  }

  renderLoadingTab() {
    return (<LoadingTable />);
  }

  renderTab([key, render]) {
    let content;
    if (typeof render === 'function') {
      content = render.call(null, this.state, this.props);
      if (content === null) {
        return null;
      }
    } else {
      content = render;
    }

    let link;
    if (this.props.disabled) {
      link = (<span className="disabled">{content}</span>);
    } else {
      link = (
        <a href="#" onClick={this.onTabChange.bind(this, key)}>
          {content}
        </a>
      );
    }

    return (
      <li key={key} className={this.state.tab === key ? 'active' : ''}>
        {link}
      </li>
    );
  }

  renderContent(tab, cache) {
    return this.props.renderContent({ tab, cache, onCacheChange: this.onTabStateChange });
  }

  render() {
    let { tab } = this.state;
    let tabs = Object.entries(this.props.tabs || this.constructor.tabs).map(this.renderTab);
    let tabList;
    if (1 < tabs.length) {
      tabList = (<ul className="page-tabs mb-2">{tabs}</ul>)
    }
    return (
      <Fragment>
        {tabList}
        {this.renderContent(tab, this.cache[tab])}
      </Fragment>
    );
  }
}
