import ReactDOM from 'react-dom'

export default node => ({
  unmount() {
    ReactDOM.unmountComponentAtNode(node);
  },
  render(component) {
    return ReactDOM.render(component, node);
  }
});
