import { Component } from 'react'
import { TooltipWrap } from 'share/components/tooltip'
import railsFetch from 'share/services/rails-fetch'

const PERIOD = process.env.NODE_ENV === 'production' ? 15000 : 15000000;

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = { status: props.initStatus };
    this.updateStatus = this.updateStatus.bind(this);
  }

  componentDidMount() {
    this.timeoutId = setTimeout(this.updateStatus, PERIOD);
  }

  updateStatus() {
    railsFetch(Routes.bgJobsStatus()).
      then(resp => resp.status === 200 ? resp.json().then(json => json.data.active) : false).
      then(status => {
        if (this.state.status !== status) {
          if (status) {
            document.querySelectorAll('[eva-bg-job-node]').forEach(node => node.remove());
          } else {
            window.location.reload();
            return;
          }
        }
        this.setState({ status }, () =>
          this.timeoutId = setTimeout(this.updateStatus, PERIOD));
      });
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  render() {
    if (!this.state.status) return null;

    return (
      <TooltipWrap
        value="core.bg_job_warning"
        placement="left"
        render={ref =>
          <div ref={ref} className="bg-job-status">
            <span className="fa fa-cog" />
          </div>
        }
      />
    );
  }
}
