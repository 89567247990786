import { initializeApp } from 'babel-loader!firebase/app'
import { getMessaging, getToken, deleteToken, onMessage} from 'firebase/messaging'
import { I18n } from './i18n'
import noop from 'lodash-es/noop'

const alarmPath = data => {
  switch(window.EVA.app.id) {
    case 'tws':
      return `/alarms${data.type == 1 ? '' : '/external'}/${data.alarm_id}`;
    case 'ews':
      return `/alarms/${data.location_id}/${data.alarm_id}`;
    default:
      return null;
  }
}

const processNotification = (alarmStatus, kind) => {
  let key;
  if(alarmStatus == 2 || alarmStatus == 1) {
    key = (kind == 1) ? 'create' : 'update';
  } else if(alarmStatus == 3 || alarmStatus == 4) {
    key = 'close';
  } else if(alarmStatus == 5) {
    key = 'publish_info';
  } else {
    return;
  }

  return [I18n.t(`ui.firebase.${key}`), key];
}

const getSoundName = key => {
  switch(key) {
    case 'update':
    case 'close':
      return 'update';
    case 'publish_info':
      return 'info';
    default:
      return 'general';
  }
}

const showNotification = (path, message, key) => {
  let notification = new Notification(message, { icon: '/files/images/a-letter.png', silent: true });
  notification.addEventListener('show', () => {
    sessionStorage.removeItem('eva:fcm');
    let audio = new Audio(`/files/sounds/${getSoundName(key)}.wav`);
    audio.play();
  });

  notification.addEventListener('click', () => {
    window.location = path;
    window.focus();
    notification.close();
  });
}

// cache push message in case page reloads when push come
const restoreNotification = () => {
  if(!sessionStorage.getItem('eva:fcm')) return;

  let [path, message, key] = JSON.parse(sessionStorage.getItem('eva:fcm'));
  showNotification(path, message, key);
}

const handleNotification = (path, message, key) => {
  sessionStorage.setItem('eva:fcm', JSON.stringify([path, message, key]));
  showNotification(path, message, key);
}

const requestPermission = () => {
  if (Notification.permission === 'granted') {
    return new Promise(resolve => resolve());
  } else if (Notification.permission !== 'denied') {
    return Notification.requestPermission();
  } else {
    return Promise.reject('the permission was blocked');
  }
}

const setupFirebase = (config, { vapidKey, ...credentials }) => {
  const app = initializeApp(credentials);
  const messaging = getMessaging(app);

  if(!config) {
    if (location.pathname.includes('/session/new')) {
      deleteToken(messaging);
    }
    return;
  }

  const syncToken = token => {
    if (config.token == token && config.active) return;
    if (config.token) deleteToken(messaging);

    $.ajax('/fcm_push', {
      method: 'PATCH',
      contentType: 'application/json',
      data: JSON.stringify({ token: token })
    }).then(() => config.token = token);
  }

  requestPermission().then(() => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' })
      .then(() => navigator.serviceWorker.ready)
      .then(registration => {
        registration.active.postMessage({
          id: 'eva:init',
          locale: config.locale,
          locationId: gon.app.id
        });
        restoreNotification();

        return getToken(messaging, { vapidKey }).then((token) => {
          syncToken(token);
          onMessage(messaging, ({ data }) => {
            let config = processNotification(data.alarm_status, data.kind);
            if (!config) return;

            let path = alarmPath(data);
            if (path === null) return;

            handleNotification(path, config[0], config[1]);

            if (location.pathname.includes(data.alarm_id)) {
              setTimeout(() => location.reload(), 2000);
            }
          });
        });
      });
  }, noop);

  // refresh token
  // messaging.onTokenRefresh(() => messaging.getToken().then(syncToken));
}

if (window.EVA.firebase && ('serviceWorker' in navigator) && ('Notification' in window)) {
  setupFirebase(window.gon.fcm, window.EVA.firebase);
}
