import Modal, { openModal, ModalHeader } from '../components/modal'
import { Translate } from '../services/i18n'

export default node => {
  node.addEventListener('click', e => {
    e.preventDefault();
    openModal(props => (
      <Modal {...props}>
        <ModalHeader onClose={props.onSuccess}>
          EVALARM
          <br />
          <Translate value="core.terms_header" />
        </ModalHeader>
        <div className="modal-body">
          <iframe src={e.target.getAttribute('href')} className="landing_terms" />
        </div>
      </Modal>
    ), { skin: 'terms', size: 'lg' });
  });
}
