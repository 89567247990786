import setupLegacy from './core/legacy'
import setupLayout from './core/layout'

import { I18n } from 'share/services/i18n'
import { Provider } from 'react-redux'
import attachTermsModal from './services/attach-terms-modal'
import createReactRoot from './services/createReactRoot'
import merge from 'lodash-es/merge'
import './services/firebase'

setupLegacy();
setupLayout();

let current = EVA.I18n[gon.app.locale.current];
let gI18n = gon.I18n || {};
I18n.setup(gon.app.locale, {
  ...current,
  ...gI18n,
  attrs: merge({}, current.attrs, gI18n.attrs),
  enums: merge({}, current.enums, gI18n.enums),
  tooltip: merge({}, current.tooltip, gI18n.tooltip),
  ui: merge({}, current.ui, gI18n.ui)
});

window.setupPage = () => {
  if(window.app) {
    window.app.init();
  }
}

window.startPendingDownload = path => {
  if (!path) return;
  window.addEventListener('DOMContentLoaded', () => window.location = path);
}

window.renderReactSections = schema => {
  Object.entries(schema).forEach(([nodeId, config]) => {
    let node = document.getElementById(nodeId);
    if(node === null) return;

    let Component, props;
    if(Array.isArray(config)) {
      Component = config[0];
      props = config[1];
    } else {
      Component = config;
    }

    createReactRoot(node).render(<Component {...props} />);
  });
}

window.renderReactReduxSections = (store, schema) => {
  Object.entries(schema).forEach(([nodeId, config]) => {
    let node = document.getElementById(nodeId);
    if (node === null) return;
    let Component, props;
    if (Array.isArray(config)) {
      Component = config[0];
      props = config[1];
    } else {
      Component = config;
    }
    let section = (
      <Provider store={store}>
        <Component {...props} />
      </Provider>
    );
    createReactRoot(node).render(section);
  });
}

window.renderReactFormInNg = (nodeId, component, scope, custom = {}) => {
  let root = createReactRoot(document.getElementById(nodeId));

  let props = {
    onSuccess() {
      scope.reloadInfo();
      root.unmount();
    },
    onCancel() {
      root.unmount();
      scope.mode.reset();
      scope.$digest();
    },
    env: gon.env,
    ...custom
  };
  root.render(React.createElement(component, props));
  window.scrollToTop();
}

window.addEventListener('load', () => {
  let termsLink = document.querySelector('a[role=open-terms]');
  if (termsLink) {
    attachTermsModal(termsLink);
  }
});
